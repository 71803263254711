import React, { useState, useEffect, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import { JUCESend, JUCEReceive } from '../../context/JUCE';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_SOUND_IMAGE } from '../../api/APIConfig';
import { fetchSoundById } from '../../api/APIManager';
import { AuthContext } from '../../context/AuthContext';
import { isJUCE } from '../../context/JUCE';
import { Analytics } from '../../api/Analytics/AnalyticsService';
import {
  PlayIcon,
  PauseIcon,
  LikeIcon,
  MoreIcon,
  DownloadIcon,
  DragIcon,
  CheckIcon,
  TokenBadge
} from '../../assets/Icons';
import { CustomPopover, CustomDialog } from '../CustomComponents';
import { SoundCardDialogs } from './SoundCardDialogs';
import EditSound from '../../pages/EditSound';
import Spinner from '../Spinner';
import AISampleDialog from './AISampleDialog';
import { Sparkles } from 'lucide-react';
import ActionButton from '../ActionButton';


export const SoundCard = ({ 
  sound: initialSound, 
  onLike, 
  onUnlike, 
  onPurchase, 
  onPlay, 
  onDownload, 
  currentPlayingSound,
  isPlaying,
  isPurchasing,
  onDragStart,
  onPurchaseSuccess,
  isSelectable = false,
  isSelected = false,
  onSelect
}) => {
  const navigate = useNavigate();
  const [sound, setSound] = useState(initialSound);
  // Track if the card is in a draggable state
  const [isDraggable, setIsDraggable] = useState(false);
  // Track if currently dragging
  const [isDragging, setIsDragging] = useState(false);
  // State for tracking mouse position during drag
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [continueToPurchase, setContinueToPurchase] = useState(false);
  const [continueToDownload, setContinueToDownload] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAISampleDialogOpen, setIsAISampleDialogOpen] = useState(false);

  const cardRef = useRef(null);
  const timeoutRef = useRef(null);
  const { user } = useContext(AuthContext);
  const imageUrl = sound.image || sound.image2 || DEFAULT_SOUND_IMAGE;
  const hasAudioPreview = !!sound.audio_preview;
  const isCurrentSound = currentPlayingSound && currentPlayingSound.id === sound.id;
  const isOwner = user && (user.id === sound.owner_id || user.soundware_id === sound.owner_id || user.admin);
  const buttonClass = "h-8 flex flex-col items-center justify-center text-white rounded transition-all duration-200";
  // canDrag is now calculated based on ActionButton's logic

  useEffect(() => {
    setSound(initialSound);
  }, [initialSound]);

  // Track when the card becomes draggable based on sound status
  useEffect(() => {
    // A sound is draggable when it's in JUCE, purchased, and has accepted license if required
    const canDrag = isJUCE() && sound && sound.is_purchased && 
      (sound.is_downloaded || sound.has_downloaded) &&
      (!sound.license || sound.has_accepted_required_license);
    setIsDraggable(canDrag);
  }, [sound]);
  
  // Listen for sound download completion from ActionButton
  useEffect(() => {
    const handleSoundDownloadComplete = (e) => {
      // Only update if this is the same sound
      if (e.detail.soundId === sound.id) {
        // Update the local sound object to mark as downloaded
        setSound(prevSound => ({
          ...prevSound,
          is_downloaded: true,
          has_downloaded: true
        }));
        
        // This will trigger the other useEffect to update isDraggable
      }
    };
    
    // Listen for custom download complete event from ActionButton
    window.addEventListener('sound-download-complete', handleSoundDownloadComplete);
    
    return () => {
      window.removeEventListener('sound-download-complete', handleSoundDownloadComplete);
    };
  }, [sound.id]);
  
  // Handle mouse movement to update drag indicator position, matching ActionButton behavior
  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (e) => {
        // Use clientX/Y for viewport coordinates - no bounds checking needed
        setMousePosition({ x: e.clientX, y: e.clientY });
      };
      
      // Use capture phase to ensure we get the events first
      window.addEventListener('mousemove', handleMouseMove, { capture: true });
      
      // Set up a global drag end listener to ensure cleanup
      const handleGlobalDragEnd = () => {
        setIsDragging(false);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      };
      
      window.addEventListener('dragend', handleGlobalDragEnd);
      
      return () => {
        window.removeEventListener('mousemove', handleMouseMove, { capture: true });
        window.removeEventListener('dragend', handleGlobalDragEnd);
      };
    }
  }, [isDragging]);

  // Handle card dragging - fully matched with ActionButton implementation
  const handleCardDragStart = (e) => {
    if (!isDraggable) return;
    
    e.preventDefault();
    setIsDragging(true);
    
    try {
      // Initialize mouse position at the start of dragging
      setMousePosition({ x: e.clientX, y: e.clientY });
      
      // Use a transparent drag image to hide the default browser drag image
      const transparentImage = new Image();
      transparentImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // 1px transparent GIF
      e.dataTransfer.setDragImage(transparentImage, 0, 0);
      e.dataTransfer.setData('text/plain', JSON.stringify(sound));
      
      // Call parent's onDragStart if provided
      if (onDragStart) {
        onDragStart(e, sound);
      }
      
      // Add a safety timeout to ensure the drag state is reset even if JUCE never responds
      const safetyTimeout = setTimeout(() => {
        setIsDragging(false);
      }, 100000); // 100 second timeout to match ActionButton
      
      // Store the timeout in a ref so we can clear it when needed
      timeoutRef.current = safetyTimeout;
      
      // Direct JUCE integration - critical for drag functionality
      JUCESend('dragExport', sound);
      
      // Dispatch a custom event to notify ActionButton that dragging has started
      const dragStartEvent = new CustomEvent('soundcard-drag-start', { detail: { soundId: sound.id } });
      window.dispatchEvent(dragStartEvent);
      
      // Set up a specific listener for this export completion
      const handleExportComplete = ({ eventName }) => {
        // Match exactly the behavior in ActionButton for consistency
        if (eventName === 'dragExportComplete') {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
          setIsDragging(false);
          JUCEReceive.off('JUCEReceive', handleExportComplete);
          
          // Dispatch a custom event to notify ActionButton that dragging has ended
          const dragEndEvent = new CustomEvent('soundcard-drag-end', { detail: { soundId: sound.id } });
          window.dispatchEvent(dragEndEvent);
        } else if (eventName === 'dragExportDownloadComplete') {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
          }
          setIsDragging(false);
          JUCEReceive.off('JUCEReceive', handleExportComplete);
          
          // Dispatch a custom event to notify ActionButton that dragging has ended
          const dragEndEvent = new CustomEvent('soundcard-drag-end', { detail: { soundId: sound.id } });
          window.dispatchEvent(dragEndEvent);
        }
      };
      
      JUCEReceive.on('JUCEReceive', handleExportComplete);
    } catch (error) {
      console.error('Error during drag export:', error);
      setIsDragging(false);
    }
  };
  
  // Handle drag end event
  const handleCardDragEnd = () => {
    setIsDragging(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    
    // Dispatch a custom event to notify ActionButton that dragging has ended
    const dragEndEvent = new CustomEvent('soundcard-drag-end', { detail: { soundId: sound.id } });
    window.dispatchEvent(dragEndEvent);
  };

  const handleClick = (e) => {
    if (e.target.closest('button') || e.target.closest('.drag-icon')) return;
    if (isDragging) return; // Don't play if we're dragging
    
    onPlay(sound);
    Analytics.trackSoundPlay(sound);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleLikeToggle = (e) => {
    e.stopPropagation();
    const newLikedState = !sound.is_liked;
    
    setSound(prevSound => ({
      ...prevSound,
      is_liked: newLikedState
    }));

    if (newLikedState) {
      onLike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    } else {
      onUnlike(sound.id).catch(() => {
        setSound(prevSound => ({
          ...prevSound,
          is_liked: !newLikedState
        }));
      });
    }
    Analytics.trackSoundLike(sound, newLikedState);
  };

  const handlePurchase = (e) => {
    e.stopPropagation();
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToPurchase(true);
      return;
    }
    processPurchase();
  };

  const processPurchase = async () => { 
    try {
      await onPurchase(sound.id); 
      const updatedSound = await fetchSoundById(sound.id);
      setSound(updatedSound);
      Analytics.trackSoundPurchase(sound);
      if (onPurchaseSuccess) {
        onPurchaseSuccess(sound.id);
        if (continueToDownload) {
          handleDownload();
        }
      }
    } catch (error) {
      console.error('Purchase failed:', error);
    }
  };

  const handleDownload = (e) => {
    e?.stopPropagation();
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToDownload(true);
      return;
    }
    setIsDownloading(true);
    onDownload(sound.id)
      .then(() => {
        Analytics.trackSoundDownload(sound);
        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.error('Download failed:', error);
      });
  };

  const handleArtistClick = (e) => {
    e.stopPropagation();
    navigate(`/${sound.owner.username}`);
  };

  const handleLicenseSuccess = (updatedSound) => {
    setSound(updatedSound);
    if (continueToPurchase) {
      processPurchase();
      setContinueToPurchase(false);
    }
    if (continueToDownload) {
      handleDownload();
      setContinueToDownload(false);
    }
    Analytics.trackLicenseAccept(sound, sound.license);
  };

  const handleEditSuccess = (updatedSound) => {
    setSound(updatedSound);
    setIsEditModalOpen(false);
  };

  const renderLicenseSection = () => {
    return (
      <div 
        className="flex flex-col items-center justify-center w-24 md:w-24 space-y-1 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsLicenseModalOpen(true);
          setContinueToDownload(false);
          setContinueToPurchase(false);
        }}
      >
        {sound.license ? (
          <>
            <span
              className="text-xs text-text-secondary hover:text-text-primary transition-colors duration-200 text-center"
              title={sound.license.name}
            >
              {sound.license.name}
            </span>
            <div className="h-4 flex items-center justify-center">
              {sound.has_accepted_required_license && (
                <CheckIcon className="text-green-500" />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="h-4 flex items-center justify-center">
              <CheckIcon className="text-green-500" />
            </div>
          </>
        )}
      </div>
    );
  };


  const popoverContent = (
    <div 
      className="w-40 shadow-lg border border-white/10 rounded-lg overflow-hidden"
      onClick={(e) => e.stopPropagation()}
    >
      {user?.admin && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsAISampleDialogOpen(true);
            setIsMenuOpen(false);
          }}
          className="w-full px-3 py-1.5 text-sm text-left hover:bg-gray-700/50 md:hidden"
        >
          Generate AI Sample
        </button>
      )}
      
      {isOwner && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsEditModalOpen(true);
            setIsMenuOpen(false);
          }}
          className="w-full px-3 py-1.5 text-sm text-left hover:bg-gray-700/50"
        >
          Edit
        </button>
      )}

      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsReportDialogOpen(true);
          setIsMenuOpen(false);
        }}
        className="w-full px-3 py-1.5 text-sm text-left hover:bg-gray-700/50"
      >
        Report
      </button>
    </div>
  );

  // Create drag overlay component for consistent behavior with ActionButton
  const dragOverlay = createPortal(
    isDragging ? (
      <div
        id="sound-card-drag-indicator"
        style={{
          position: 'fixed', // Fixed to viewport
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          pointerEvents: 'none',
          zIndex: 9999,
        }}
      >
        <img 
          src={imageUrl}
          alt="Sound"
          style={{
            position: 'absolute',
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            borderRadius: '4px',
            transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            border: '0px solid white',
          }}
        />
      </div>
    ) : null,
    document.body // Render directly in body
  );
  
  return (
    <>
      <div 
        ref={cardRef}
        className={`
          relative p-0 flex items-center h-16 md:h-16 
          transition-all duration-200 ease-in-out group cursor-pointer
          ${isCurrentSound 
            ? 'bg-accent-end/20 border-l-4 border-l-accent-end border-b border-b-white/5' 
            : 'bg-transparent hover:bg-white/5 border-l-4 border-l-transparent border-b border-b-white/5'
          }
          
        `}
        draggable={isDraggable}
        onDragStart={handleCardDragStart}
        onDragEnd={handleCardDragEnd}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {/* Image section */}
        <div 
          className="relative w-12 h-12 flex-shrink-0 mr-3 rounded-sm overflow-hidden"
        >
          <img src={imageUrl} alt={`${sound.name} artwork`} className="w-full h-full object-cover" draggable="false" />
          {hasAudioPreview && (
            <div className={`
              absolute inset-0 flex justify-center items-center bg-black/40
              ${isHovered || isSelected || isCurrentSound ? 'opacity-100' : 'opacity-0 md:opacity-0'}
              transition-opacity duration-200
            `}>
              {isCurrentSound && isPlaying ? <PauseIcon className="w-5 h-5" /> : <PlayIcon className="w-5 h-5" />}
            </div>
          )}
        </div>
        
        {/* Main grid section */}
        <div className="flex-grow grid grid-cols-4 md:grid-cols-6 gap-2 overflow-hidden items-center">
          <div className="col-span-4 md:col-span-2">
            <div className="font-medium text-sm text-text-primary truncate select-none">
              {sound.name}
            </div>
            <div className="flex items-center">
              <span 
                className="text-text-secondary text-xs truncate select-none cursor-pointer hover:underline inline-block"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArtistClick(e);
                }}
              >
                {sound.owner?.username}
              </span>
            </div>
          </div>
          
          <div className="hidden md:flex items-center">
            {sound.instruments && sound.instruments.length > 0 ? (
              <span className="text-text-secondary text-xs truncate">
                {sound.instruments.slice(0, 2).map(i => i.name).join(', ')}
              </span>
            ) : (
              <span className="text-text-secondary text-xs">-</span>
            )}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {sound.type ? sound.type.name : '-'}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {sound.key || '-'}
          </div>
          
          <div className="hidden md:block text-text-secondary text-xs truncate">
            {typeof sound.bpm === 'number' && sound.bpm > 0 ? `${sound.bpm}` : '-'}
          </div>
        </div>

        {/* Action buttons section */}
        <div className="flex items-center space-x-2 md:space-x-3">

        {user?.admin && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsAISampleDialogOpen(true);
              }}
              className="hidden md:flex p-1 rounded-full hover:bg-accent-end/20 transition-all duration-200 opacity-0 group-hover:opacity-100"
              title="Generate AI Sample"
            >
              <Sparkles 
                className="w-4 h-4 text-text-secondary group-hover:text-accent-end transition-colors duration-200" 
              />
            </button>
          )}
          
          <button 
            onClick={(e) => {
              e.stopPropagation();
              handleLikeToggle(e);
            }}
            className={`
              p-1 rounded-full hover:bg-gray-700/50 transition-all duration-200
              ${sound.is_liked ? 'opacity-100' : 'md:opacity-0 md:group-hover:opacity-100'}
            `}
          >
            <LikeIcon className="w-4 h-4" filled={sound.is_liked} />
          </button>
          
          <div className="hidden md:block w-18 flex justify-center md:opacity-0 md:group-hover:opacity-100 transition-all duration-200">
            {renderLicenseSection()}
          </div>
          
          <div className="w-8 md:w-16 flex justify-center">
          <ActionButton 
              sound={sound}
              isPurchasing={isPurchasing}
              isDownloading={isDownloading}
              onPurchase={handlePurchase}
              onDownload={handleDownload}
              isJUCE={isJUCE()}
              isSelectable={isSelectable}
              isSelected={isSelected}
              onSelect={onSelect}
              className="ml-4 md:ml-0 w-20"
            />
          </div>

          <div className="md:opacity-0 md:group-hover:opacity-100 transition-all duration-200">
            <CustomPopover
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              trigger={
                <button 
                  className="p-1.5 hover:bg-gray-700/50 rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen(!isMenuOpen);
                  }}
                >
                  <MoreIcon className="w-4 h-4" />
                </button>
              }
              content={popoverContent}
            />
          </div>
        </div>
      </div>

      {/* Dialogs remain the same */}
      <SoundCardDialogs
        sound={sound}
        setSound={setSound}
        isLicenseModalOpen={isLicenseModalOpen}
        setIsLicenseModalOpen={setIsLicenseModalOpen}
        isReportDialogOpen={isReportDialogOpen}
        setIsReportDialogOpen={setIsReportDialogOpen}
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        onLicenseSuccess={handleLicenseSuccess}
        onEditSuccess={handleEditSuccess}
        isAISampleDialogOpen={isAISampleDialogOpen}
        setIsAISampleDialogOpen={setIsAISampleDialogOpen}
      />
      {dragOverlay}

      <AISampleDialog
        isOpen={isAISampleDialogOpen}
        onClose={() => setIsAISampleDialogOpen(false)}
        sound={sound}
      />
    </>
  );
};

export default SoundCard;