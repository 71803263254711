// SoundPacksPage.jsx
import React from 'react';
import SoundPacksList from '../components/SoundPackBrowser/SoundPacksList';

const SoundPacksPage = () => {
  return (
    <div className="relative w-full h-full">
      {/* Content using the global gradient from Layout.js */}
      <div className="relative z-10">
        <SoundPacksList 
          title="Sound Packs"
          filters={{has_min_sounds: true}}  
          showCreateButton={true}
        />
      </div>
    </div>
  );
};

export default SoundPacksPage;