import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { DragIcon, DownloadIcon, TokenBadge } from '../assets/Icons';
import { AuthContext } from '../context/AuthContext';
import PricingModal from './PricingSection';
import Spinner from './Spinner';
import SpinnerFull from './SpinnerFull';
import { getDownloadURL, acceptLicense } from '../api/APIManager';
import { JUCESend, JUCEReceive } from '../context/JUCE';
import { CustomDialog } from './CustomComponents';

const ActionButton = ({
  sound,
  isPurchasing = false,
  isDownloading = false,
  onPurchase,
  onDownload,
  onDragStart = null, // Made optional since we'll handle drag internally
  isJUCE = false,
  isSelectable = false,
  isSelected = false,
  onSelect,
  className = '',
  canDrag = null, // Made optional and will be calculated internally if not provided
}) => {
  const { user } = useContext(AuthContext);
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [isAcceptingLicense, setIsAcceptingLicense] = useState(false);
  const [canAcceptLicense, setCanAcceptLicense] = useState(false);
  const [acceptTimer, setAcceptTimer] = useState(null);
  const [continueToAction, setContinueToAction] = useState(null);
  const [isJUCEDownloading, setIsJUCEDownloading] = useState(false);
  // Track if the sound has been downloaded in JUCE
  const [isJUCEDownloaded, setIsJUCEDownloaded] = useState(false);
  
  // State for tracking mouse position during drag
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  // Listen for SoundCard drag events to sync the spinner state
  useEffect(() => {
    const handleSoundCardDragStart = (e) => {
      // Only respond if this is the same sound
      if (e.detail.soundId === sound.id) {
        setIsDragging(true);
      }
    };
    
    const handleSoundCardDragEnd = (e) => {
      // Only respond if this is the same sound
      if (e.detail.soundId === sound.id) {
        setIsDragging(false);
      }
    };
    
    // Listen for custom events from SoundCard
    window.addEventListener('soundcard-drag-start', handleSoundCardDragStart);
    window.addEventListener('soundcard-drag-end', handleSoundCardDragEnd);
    
    return () => {
      window.removeEventListener('soundcard-drag-start', handleSoundCardDragStart);
      window.removeEventListener('soundcard-drag-end', handleSoundCardDragEnd);
    };
  }, [sound.id]);
  
  // Calculate canDrag internally if not provided externally
  const canDragInternal = canDrag !== null ? canDrag : 
    isJUCE && sound && sound.is_purchased && (sound.is_downloaded || isJUCEDownloaded);
  
  // Base button class with consistent width and spacing
  const baseButtonClass = "w-[72px] h-[36px] px-2 font-medium text-sm border border-white/20 rounded-full flex items-center justify-center mx-1";
  
  // Add background styles for non-loading states
  const buttonClass = `${baseButtonClass} ${isPurchasing || isDownloading ? '' : 'bg-black/50 hover:bg-[#2E1F40]'} text-white transition-all duration-200`;
  
  // Helper function to render license modal
  const renderLicenseModal = () => (
    <CustomDialog 
      isOpen={isLicenseModalOpen} 
      onClose={(e) => {
        e?.stopPropagation();
        setIsLicenseModalOpen(false);
        setContinueToAction(null);
      }}
      title={`${sound.license?.name || 'License'} Agreement`}
    >
      <div className="space-y-4" onClick={(e) => e.stopPropagation()}>
        <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
          <iframe
            src={sound.license?.url}
            className="w-full h-full border-0"
            title="License Agreement"
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsLicenseModalOpen(false);
              setContinueToAction(null);
            }}
            className="px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200 text-text-primary"
          >
            Cancel
          </button>
          {!sound.has_accepted_required_license && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleAcceptLicense();
              }}
              disabled={!canAcceptLicense || isAcceptingLicense}
              className={`
                px-4 py-2 rounded bg-accent-start text-text-primary
                ${(!canAcceptLicense || isAcceptingLicense) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-accent-end'}
                transition-colors duration-200
              `}
            >
              {isAcceptingLicense ? (
                <Spinner />
              ) : !canAcceptLicense ? (
                'Please wait...'
              ) : (
                'Accept'
              )}
            </button>
          )}
        </div>
      </div>
    </CustomDialog>
  );

  // Check if license needs to be accepted before JUCE drag
  const handleDragCheck = (e) => {
    // First check for license requirements
    if (!sound.has_accepted_required_license && sound.license) {
      e.preventDefault();
      e.stopPropagation();
      setIsLicenseModalOpen(true);
      setContinueToAction('drag');
      return false;
    }
    return true;
  };
  
  // Internal drag handler - self-contained within ActionButton
  // Handle mouse movement to update drag indicator position
  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = (e) => {
        // Use clientX/Y for viewport coordinates - no bounds checking needed
        setMousePosition({ x: e.clientX, y: e.clientY });
      };
      
      // Use capture phase to ensure we get the events first
      window.addEventListener('mousemove', handleMouseMove, { capture: true });
      
      return () => {
        window.removeEventListener('mousemove', handleMouseMove, { capture: true });
      };
    }
  }, [isDragging]);

  // Make handleDragStart accessible via ref
  const handleDragStart = useCallback(async (e) => {
    if (!canDragInternal) return;
    
    // Check license requirements first
    if (!handleDragCheck(e)) return;
    
    e.preventDefault();
    setIsDragging(true);
    
    try {
      // If onDragStart is provided, call it first (for compatibility)
      if (onDragStart) {
        onDragStart(e, sound);
      }
      
      // Initialize mouse position at the start of dragging
      setMousePosition({ x: e.clientX, y: e.clientY });
      
      // Use a transparent drag image to hide the default
      const transparentImage = new Image();
      transparentImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'; // 1px transparent GIF
      e.dataTransfer.setDragImage(transparentImage, 0, 0);
      e.dataTransfer.setData('text/plain', JSON.stringify(sound));
      
      // Add a safety timeout to ensure the drag state is reset even if JUCE never responds
      const safetyTimeout = setTimeout(() => {
        setIsDragging(false);
      }, 100000); // 100 second timeout
      
      JUCESend('dragExport', sound);
      
      // Set up a specific listener for this export
      const handleExportComplete = ({ eventName }) => {
        if (eventName === 'dragExportComplete') {
          clearTimeout(safetyTimeout);
          setIsDragging(false);
          JUCEReceive.off('JUCEReceive', handleExportComplete);
        } else if (eventName === 'dragExportDownloadComplete') {
          clearTimeout(safetyTimeout);
          setIsDragging(false);
          JUCEReceive.off('JUCEReceive', handleExportComplete);
        }
      };
      
      JUCEReceive.on('JUCEReceive', handleExportComplete);
    } catch (error) {
      console.error('Error during drag export:', error);
      setIsDragging(false);
    }
  }, [canDragInternal, sound, onDragStart]);

  // We're now handling drag in SoundCard directly
  
  const handlePurchaseClick = (e) => {
    e.stopPropagation();
    
    // Check if license needs to be accepted
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToAction('purchase');
      return;
    }
    
    // Check if user has enough credits
    const hasEnoughCredits = user.credits >= sound.cost_in_credits;
    
    if (!hasEnoughCredits && !user.active_subscriptions?.some(sub => 
      sub.toLowerCase().includes('soundbank')
    )) {
      // Show pricing modal if user doesn't have enough credits and no active subscription
      setIsPricingOpen(true);
    } else {
      // Proceed with purchase if they have enough credits
      onPurchase(e);
    }
  };
  
  const handleDownloadClick = (e) => {
    e.stopPropagation();
    
    // Check if license needs to be accepted
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToAction('download');
      return;
    }
    
    // Proceed with download
    onDownload(e);
  };

  // Handle JUCE download functionality
  const handleJUCEDownload = async (e) => {
    e.stopPropagation();
    
    // Check if license needs to be accepted
    if (!sound.has_accepted_required_license && sound.license) {
      setIsLicenseModalOpen(true);
      setContinueToAction('juceDownload');
      return;
    }
    
    setIsJUCEDownloading(true);

    try {
      // Get download URL and send to JUCE
      const downloadUrl = await getDownloadURL(sound.id);
      if (!downloadUrl) {
        throw new Error('Failed to get download URL');
      }
      
      const soundWithUrl = { ...sound, download_url: downloadUrl };
      
      // Send the download event to JUCE
      JUCESend('downloadSound', soundWithUrl);
      
      // After 3 seconds, mark the sound as downloaded
      setTimeout(() => {
        // Update sound object to mark as downloaded
        sound.is_downloaded = true;
        setIsJUCEDownloaded(true);
        setIsJUCEDownloading(false);
        
        // Dispatch a custom event to notify SoundCard that the sound is now downloadable
        const downloadCompleteEvent = new CustomEvent('sound-download-complete', { 
          detail: { soundId: sound.id } 
        });
        window.dispatchEvent(downloadCompleteEvent);
      }, 3000);
      
    } catch (error) {
      console.error('Error during sound download:', error);
      setIsJUCEDownloading(false);
    }
  };
  
  // Handle license acceptance
  const handleAcceptLicense = async () => {
    if (!sound.license || !sound.license.id) return;

    setIsAcceptingLicense(true);
    try {
      await acceptLicense(sound.license.id);
      
      // Update the sound object locally
      sound.has_accepted_required_license = true;
      
      // Close the modal
      setIsLicenseModalOpen(false);
      
      // Continue with the original action
      if (continueToAction === 'purchase') {
        onPurchase();
      } else if (continueToAction === 'download') {
        onDownload();
      } else if (continueToAction === 'juceDownload') {
        // Trigger download for JUCE
        handleJUCEDownload({ stopPropagation: () => {} });
      } else if (continueToAction === 'drag') {
        // Trigger a simulated drag event after license is accepted
        // We don't use the original event because it's no longer valid
        // Instead, we update canDragInternal which will enable dragging
      }
      
      // Reset the continue action
      setContinueToAction(null);
    } catch (error) {
      console.error('Error accepting license:', error);
      alert('Failed to accept license. Please try again.');
    } finally {
      setIsAcceptingLicense(false);
    }
  };
  
  // License modal effect
  useEffect(() => {
    // Clean up timer when modal closes
    if (!isLicenseModalOpen && acceptTimer) {
      clearTimeout(acceptTimer);
      setAcceptTimer(null);
    }

    // Set new timer when modal opens
    if (isLicenseModalOpen) {
      setCanAcceptLicense(false);
      if (acceptTimer) {
        clearTimeout(acceptTimer);
      }
      const timer = setTimeout(() => {
        setCanAcceptLicense(true);
      }, 3000);
      setAcceptTimer(timer);
    }
  }, [isLicenseModalOpen]);

  // Early returns need to come after all hook declarations
  if (sound.type === undefined) {
    return null;
  }
  
  // Create drag overlay component
  const renderDragOverlay = () => {
    if (!isDragging) return null;
    
    return createPortal(
      <div
        id="drag-indicator"
        style={{
          position: 'fixed', // Fixed to viewport
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          pointerEvents: 'none',
          zIndex: 9999,
        }}
      >
        <img 
          src={sound.image || sound.image2 || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
          alt="Sound"
          style={{
            position: 'absolute',
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
            width: '100px',
            height: '100px',
            objectFit: 'cover',
            borderRadius: '4px',
            transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            border: '0px solid white',
          }}
        />
      </div>,
      document.body // Render directly in body
    );
  };
  
  if (isSelectable) {
    return (
      <button 
        onClick={(e) => {
          e.stopPropagation();
          onSelect?.(sound);
        }}
        className={`${buttonClass} ${className} ${
          isSelected 
            ? 'bg-accent-end' 
            : 'hover:bg-accent-end/60'
        }`}
      >
        {isSelected ? 'Remove' : 'Add'}
      </button>
    );
  }

  if (isPurchasing) {
    return (
      <button 
        disabled
        className={`${baseButtonClass} ${className}`}
      >
        <Spinner />
      </button>
    );
  }
  
  // Render the drag overlay at the end of the component
  const dragOverlay = renderDragOverlay();

  if (sound.is_purchased || user.whitelisted) {
    if (isJUCE) {
      // For JUCE integration: show download button first, then drag icon after downloaded
      return (
        <>
          {!sound.is_downloaded && !isJUCEDownloaded ? (
            <button 
              onClick={handleJUCEDownload}
              className={`${isJUCEDownloading ? baseButtonClass : buttonClass} ${className}`}
              disabled={isJUCEDownloading}
            >
              {isJUCEDownloading ? (
                <Spinner />
              ) : (
                <DownloadIcon className="w-5 h-5" />
              )}
            </button>
          ) : (
            <>
              <div 
                className={`${buttonClass} drag-icon ${canDragInternal ? 'cursor-grab active:cursor-grabbing' : 'cursor-not-allowed opacity-60'} ${className}`}
                draggable={canDragInternal}
                onDragStart={handleDragStart}
              >
                {isDragging ? <Spinner /> : <DragIcon className="w-5 h-5" />}
              </div>
              {dragOverlay}
            </>
          )}
          
          {/* License Modal */}
          {renderLicenseModal()}
        </>
      );
    } else {
      // Non-JUCE case but sound is already purchased - show download button
      return (
        <>
          <button 
            onClick={handleDownloadClick}
            className={`${isDownloading ? baseButtonClass : buttonClass} ${className}`}
            disabled={isDownloading}
          >
            {isDownloading ? (
              <Spinner />
            ) : (
              <DownloadIcon className="w-5 h-5" />
            )}
          </button>
          {renderLicenseModal()}
        </>
      );
    }
  }



  return (
    <>
      <button 
        onClick={handlePurchaseClick}
        className={`${buttonClass} ${className}`}
      >
        {sound.cost_in_credits ? (
          <div className="flex items-center justify-center gap-1">
            <span>{sound.cost_in_credits}</span>
            <TokenBadge className="w-4 h-4" />
          </div>
        ) : (
          'Get'
        )}
      </button>
      
      <PricingModal 
        isOpen={isPricingOpen}
        setIsOpen={setIsPricingOpen}
      />
      
      {/* License Modal */}
      {renderLicenseModal()}
      {dragOverlay}
    </>
  );
};

export default ActionButton;